import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonDatetime, IonHeader, IonIcon,  IonInput,  IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonRadio, IonRadioGroup,  IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { bookOutline, bugOutline, calendarOutline, checkmarkCircleOutline, returnDownBackOutline} from 'ionicons/icons';
import React, {useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppUserEmail, EmailAppResponse } from '../data/api';
import './Generic.css';
import { useGlobalContext } from '../components/MyGlobalContext';


interface RouteProps extends RouteComponentProps<{ fieldName: string }> { }
interface GenericProps extends RouteProps { }


const Email: React.FC = () => {

  const [present] = useIonAlert();
  const [userEmail, setUserEmail] = useState('');
  const { order } = useGlobalContext();
  const { searchText } = useGlobalContext();
 

  const queryEmail = useQuery("GetUserEmail", () => GetAppUserEmail(), {
    onSuccess: (data: any) => {
      setUserEmail(data);
    }
  });


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle >Email List</IonTitle>
          <IonButtons slot="end"  color="transparent">
            <IonButton onClick={() => { window.location.href = 'tabs/last'; }} >
              <IonIcon icon={checkmarkCircleOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/ReportIssue'; }} >
              <IonIcon icon={bugOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/Canlist'; }}>
              <IonIcon icon={returnDownBackOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        

        <IonCard color="warning">
          <IonCardContent>
            <IonItem color="transparent" lines="none">
              <IonIcon icon={bookOutline} color="dark" size="large" slot="start" /> &nbsp;
              <IonLabel class="ion-text-wrap">
                This will email the list you select on the home page. After you submit the request
                please check your email box for the list.
              </IonLabel>
            </IonItem>

          </IonCardContent>
        </IonCard>

        <IonCard >
          <IonCardContent>
            {queryEmail.isSuccess &&
              (
                <>
                  <IonItem color="transparent">
                    <IonLabel slot="start">
                      Your Email: 
                    </IonLabel>
                    <IonInput
                      type="text"
                      name="notes"
                      min="5"
                      max="60"
                      value={userEmail}
                    />
                  </IonItem>
                </>
              )
            }


   
            <IonButton 
              color="primary"
              expand="full"
              type="submit"
              mode="ios"
              onClick={async () => {
                if (userEmail === "") {
                  alert("Make sure that the email is correct!");
                }
                else {
                  // Process the request
                  await new Promise(resolve => setTimeout(resolve, 300));
                  EmailAppResponse(userEmail, order, searchText);
                  present("Please check your email box for the email!");
                }
              }}
            >Submit</IonButton>


          </IonCardContent>
        </IonCard>
   


      </IonContent>
    </IonPage>
  )
}

export default Email;
