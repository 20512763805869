import React  from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { homeOutline, mapOutline,  mailOutline, printOutline } from 'ionicons/icons';
import Login from './Login';
import useAuth from '../data/useAuth';
import CanList from './CanList';
import Location from './Location';
import History from './History';
import About from './About';
import Printout from './printout';
import Map from './Map';
import Email from './Email';
import ReportIssue from './Report';
import Last from './Last';

interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => {
  const auth = useAuth();
  const loggedIn: boolean = auth.user ? true : false;

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/canlist" />
        <Route path="/tabs/canlist"
          render={() => (
            loggedIn ? (
              <CanList />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/history"
          render={() => (
            loggedIn ? (
              <History />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/email"
          render={() => (
            loggedIn ? (
              <Email />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/map"
          render={() => (
            loggedIn ? (
              <Map />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/last"
          render={() => (
            loggedIn ? (
              <Last />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/location/:index"
          render={(props: any) => (
            loggedIn ? (
              <Location
               {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />


       <Route path="/tabs/ReportIssue"
          render={(props: any) => (
            loggedIn ? (
              <ReportIssue
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />
      
        <Route path="/tabs/printout"
          render={(props: any) => (
            loggedIn ? (
              <Printout
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route
            path="/tabs/login"
            render={(props: any) => (
              <Login
                {...props}
                title="Login"
                setUser={auth.setUser}
              />
          )} />


        <Route path="/tabs/about"
          render={() => (
            loggedIn ? (
              <About />
            ) : (
                <Login setUser={auth.setUser} />
            )
          )} />

      </IonRouterOutlet>



      <IonTabBar slot="bottom" >
        <IonTabButton tab="application" href="/tabs/canlist">
          {loggedIn &&
            <>
            <IonIcon icon={homeOutline} />
            <IonLabel>Home</IonLabel>
            </>
          }
        </IonTabButton>
        <IonTabButton tab="map" href="/tabs/map">
          {loggedIn &&
            <>
            <IonIcon icon={mapOutline} />
            <IonLabel>Map</IonLabel>
            </>
          } 
        </IonTabButton>
        {/*<IonTabButton tab="history" href="/tabs/history">*/}
        {/*  {loggedIn &&*/}
        {/*    <>*/}
        {/*    <IonIcon icon={documentsOutline} />*/}
        {/*    <IonLabel>history</IonLabel>*/}
        {/*    </>*/}
        {/*  }*/}
          
        {/*</IonTabButton>*/}
        <IonTabButton tab="email" href="/tabs/email"  >
          {loggedIn &&
            <>
            <IonIcon icon={mailOutline} />
            <IonLabel>
              Email</IonLabel>
            </>
          }
        </IonTabButton>

        <IonTabButton tab="printout" href="/tabs/printout"  >
          {loggedIn &&
            <>
              <IonIcon icon={printOutline} />
              <IonLabel>
                Print Date</IonLabel>
            </>
          }
        </IonTabButton>
       
        
      </IonTabBar>
    


     

    </IonTabs>

  );
};

export default MainTabs;
