import { IonButton, IonButtons,  IonContent, IonHeader, IonIcon,   IonMenuButton, IonPage,IonTitle, IonToolbar } from '@ionic/react';
import { Formik } from 'formik';
import { returnDownBackOutline } from 'ionicons/icons';
import React, {  useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Camera  from '../components/Camera';
import { UpdateAppGPSPositions } from '../data/api';


const About: React.FC = (props) => {
  let uniqueId = 183739;


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Update GPS Positions</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/canlist' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline}  />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>

          <IonButton onClick={() => UpdateAppGPSPositions()}>
            </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}



export default About;
