import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider,  IonLabel, IonMenuButton, IonPage, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { buildOutline, busOutline,  cloudDownloadOutline,  flagOutline, returnDownBackOutline} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppCanRequestListH } from '../data/api';
import './Generic.css';
import { showfile } from '../data/utilities.js';



interface RouteProps extends RouteComponentProps<{ fieldName: string }> { }
interface GenericProps extends RouteProps { }


const History: React.FC = () => {

  const [ orderH] = useState("");
  const [ searchTextH, setSearchTextH ] = useState("");


  const requestQueryH = useQuery("GetRequestHisotryQuery", () => GetAppCanRequestListH(orderH, searchTextH));


  useEffect(() => {
    requestQueryH.refetch();
  }, [searchTextH]);

  function CheckKeyEvent(props: any) {
    if (props === 'Enter') {
      requestQueryH.refetch()
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Garbage/Recycle Bin Request Hisotry</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/canlist' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <IonCard color="light">
          <IonCardContent>
            <IonItem lines="none" class="ion-no-padding" color="transparent">
              <IonSearchbar showClearButton="always" onIonChange={e => setSearchTextH(e.detail.value!)} onKeyUp={e => CheckKeyEvent(e.key!)} onIonClear={e => setSearchTextH("")} />
            </IonItem>
            <IonItem color="transparent">
              <IonLabel>Day</IonLabel>
              <IonSelect placeholder="Service Day"
                onIonCancel={() => { setSearchTextH(''); }}
                onIonChange={
                  e => {
                    var value = "@" + e.detail.value
                    setSearchTextH(value);
                  }
                }
              >
                <IonSelectOption value="Monday">Monday</IonSelectOption>
                <IonSelectOption value="Tuesday">Tuesday</IonSelectOption>
                <IonSelectOption value="Wednesday">Wednesday</IonSelectOption>
                <IonSelectOption value="Thursday">Thursday</IonSelectOption>
                <IonSelectOption value="Friday">Friday</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCardContent>
        </IonCard>


        <div>
          {requestQueryH.isLoading && (
            <IonCard>
              <IonCardContent>
                <IonItem>
                  Loading...
                </IonItem>
              </IonCardContent>
            </IonCard>
          )}
          {
            requestQueryH.isSuccess && requestQueryH.data && requestQueryH.data.length > 0 && requestQueryH.data.map((dataString: any) =>
            (
              <IonCard key={dataString.ugautoidx}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    <IonItem>
                      {dataString.ugType == "D" && (<div><IonIcon slot="start" icon={busOutline} size="large" color="success" /> Delivery </div>)}
                      {dataString.ugType == "F" && (<div><IonIcon slot="start" icon={buildOutline} size="large" color="primary" /> Fix/Repair </div>)}
                      {dataString.ugType == "P" && (<div><IonIcon slot="start" icon={flagOutline} size="large" color="danger" /> Pickup </div>)}
                    </IonItem>
                     
                  </IonCardSubtitle>
                  <IonCardTitle>  &nbsp;&nbsp;{dataString.ugseq}. &nbsp; {dataString.ugAddress} </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem lines="none">
                    <IonLabel>
                      Account #: {dataString.ugnumber} <br />
                      Requested Date: {dataString.ugdateadd} <br />
                      Recycle Date: {dataString.ugweekday} <br />
                      * {dataString.ugdesc}
                      <IonItemDivider></IonItemDivider>
                      Completed Date:  {dataString.ugdatedel} <br />
                      Closed By: {dataString.ugwmuser} <br />
                      Ref Id: {dataString.ugautoidx}
                    </IonLabel>
                  </IonItem>
                  {dataString.listDir != undefined && dataString.listDir[0] != undefined && (
                    <>
                      <IonItem lines="none" key={dataString.listDir[0].autoindex} onClick={() => showfile(dataString.listDir[0].blob, dataString.listDir[0].ext, dataString.listDir[0].filename)} >
                        <IonIcon icon={cloudDownloadOutline} size="large" color="primary" slot="start"></IonIcon>*
                        <IonLabel>
                          <img src={`data:image/png;base64,${dataString.listDir[0].blob}`} />
                        </IonLabel>
                      </IonItem>
                    </>
                    )}

                </IonCardContent>
              </IonCard>
            ))
          }
        </div>




      </IonContent>
    </IonPage>
  )
}

export default History;
