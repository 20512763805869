import React, { useEffect,useState } from 'react';
import './Marker.css';
import ModalComplete from '../components/ModalComplete';
import { IonModal } from '@ionic/react';
import ModalMultiple from '../components/ModalMultiple';


interface MarkerProps {
  id: any,
  lat: any,
  lng: any,
  name: any,
  type: any,
  label: any,
  index: any,
  key: any,
  ughotcust: any,
  refreshQuery : any,
  setRefreshQuery: any
}


const Marker: React.FC<MarkerProps> = ({ id, lat, lng, name, type, label, index, key, ughotcust, refreshQuery, setRefreshQuery }) => {

  //const { id, name, type, label, index } = props;
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [showModalMultiple, setShowModalMultiple] = useState(false);
  const [postionLabel, setPositionLabel] = useState('');
  const [colorCode, setColorCode] = useState('red');
  const [showLabel, setShowLabel] = useState('');
  const [showModalOption, setShowModalOption] = useState("1");
  const [recordHot, setRecordHot] = useState(0);

  const handleDismiss = () => {
    setRefreshQuery(refreshQuery === "Y" ? "N" : "Y");

    setShowModalMultiple(false);
  };

  // const orderList = useQuery("GetSameAddressOrderList", () => GetAppMultipleOrderList(index));

  useEffect(() => {
    if (label !== 1) {
      setShowLabel(label);
      setShowModalOption("2");
    }
    else {
      setShowModalOption("1");
      if (type === "D") {
        setShowLabel("D");
        setColorCode("green");
        setPositionLabel(name + ' [Delivery]');
      }
      else if (type === "F") {
        setShowLabel("F");
        setColorCode("blue");
        setPositionLabel(name + ' [Repair]');
      }
      else if (type === "P") {
        setShowLabel("P");
        setColorCode("red");
        setPositionLabel(name + ' [Pickup]');
      }
      else if (type === "H") {
        setShowLabel("H");
        setColorCode("#C24CF6");
        setPositionLabel(name + ' [Hot]');
      }
      else {
        setShowLabel("I");
        setColorCode("#fcb103");
        setPositionLabel(name + ' [Inventory]');
      }
    }
  }, []);


  return (
    <>
      {(showModalOption=== "1"  ) ? (<>
        <div className="marker"
          onClick={() => setShowModalComplete(true)}
          style={{ backgroundColor: colorCode, cursor: 'pointer' }}
          title={postionLabel}
        ><strong className="dot">{showLabel}</strong></div>
        <IonModal isOpen={showModalComplete} backdropDismiss={false} onDidDismiss={() => { handleDismiss(); setShowModalComplete(false); } }>
          <ModalComplete
            id={id}
            ughotcust={recordHot}
            setShowModalComplete={setShowModalComplete}
          ></ModalComplete>
        </IonModal>
      </>)
        :
        (<>
          <div className="marker"
            onClick={() => setShowModalMultiple(true)}
            style={{ backgroundColor: colorCode, cursor: 'pointer' }}
            title={postionLabel}
          ><strong className="dot">{showLabel}</strong></div>
          <IonModal isOpen={showModalMultiple}
                    backdropDismiss={false}
                    onDidDismiss={handleDismiss}>
            <ModalMultiple
              index={index}
              address={name}
              ughotcust={ughotcust}
              setShowModalMultiple={setShowModalMultiple}
            ></ModalMultiple>
          </IonModal>
        </>)}
      
    </>
  );
};

export default Marker;
