import React, { useEffect, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem,  IonButton, IonIcon, IonButtons, IonMenuButton, IonList, IonText, IonModal } from '@ionic/react';
import { Formik } from 'formik';
import { buildOutline, busOutline, closeOutline,  flagOutline } from 'ionicons/icons';
import { useQuery } from 'react-query';
import { GetAppMultipleOrderList  } from '../data/api';
import './Generic.css';
import ModalComplete from '../components/ModalComplete';



interface ModalMultipleProps {
  index: any,
  address: any,
  ughotcust: any,
  setShowModalMultiple: any
}

const ModalMultiple: React.FC<ModalMultipleProps> = ({ index, address, ughotcust, setShowModalMultiple }) => {

  let showAddress = address;
  const requestQuery = useQuery("GetMultiOrderList", () => GetAppMultipleOrderList(index),
    {
      onSuccess: (data: any) => {
        if (data === null || data.length === 0) 
           {
             setShowModalMultiple(false);
           }
      }
    }

     );
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [showAutoIdx, setShowAutoIdx] = useState<number>(0);

  return (<>
    <IonHeader >
      <IonToolbar color="warning">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle color="primary">Select Order on [{showAddress}]</IonTitle>
        <IonButtons slot="end" onClick={() => { setShowModalMultiple(false); }} color="transparent">
          <IonButton slot="end">
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">

      {requestQuery.isLoading && (
        <IonItem>
          Loading... 
        </IonItem>
      )}


      {requestQuery.isSuccess && requestQuery.data && requestQuery.data.map((dataString: any) =>(
          <>
          <IonList>
            <IonItem href='javascript:void(0);'  onClick={() => { setShowAutoIdx(dataString.ugautoidx); setShowModalComplete(true); }} key={dataString.ugautoidx}>
              {dataString.ugtype === "D" && (<div><IonIcon slot="start" icon={busOutline} color="success" size="large" />&nbsp;Delivery</div>)}
              {dataString.ugtype === "F" && (<div><IonIcon slot="start" icon={buildOutline} color="primary" size="large" />&nbsp;Fix/Repair</div>)}
              {dataString.ugtype === "P" && (<div><IonIcon slot="start" icon={flagOutline} color="danger" size="large" />&nbsp;Pickup</div>)}
              {dataString.ugtype === "I" && (<div><IonIcon slot="start" icon={flagOutline} color="warning" size="large" />&nbsp;Inventory</div>)}
              {ughotcust === 1 && <IonIcon size="large" src='/assets/icon/hot.svg'></IonIcon>}
              <IonText slot="end">
                {dataString.ugdesc}
              </IonText>
            </IonItem>
          </IonList>
         
          </>
        ))
      }

      <IonModal isOpen={showModalComplete}
        backdropDismiss={false}
        onDidDismiss={async () => await requestQuery.refetch().then(() => setShowModalComplete(false))}>
        <ModalComplete
          id={showAutoIdx}
          ughotcust={ughotcust}
          setShowModalComplete={setShowModalComplete} ></ModalComplete>
      </IonModal>
      

    </IonContent>
  </>);
}




export default ModalMultiple;

