import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,  IonCol,  IonContent, IonFab, IonFabButton, IonFabList, IonGrid, IonHeader, IonIcon, IonItem, IonItemGroup, IonLabel, IonList,  IonMenuButton, IonModal, IonPage, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar,  useIonAlert,  useIonPopover } from '@ionic/react';
import {  bugOutline, buildOutline, busOutline, checkmarkCircleOutline, chevronUpCircle, colorPalette, flagOutline, globe, navigateCircleOutline, returnDownBackOutline} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps, useHistory, } from 'react-router';
import { GetAppCanRequestList, GetAppHotCount, IsAppUserAuthenticated} from '../data/api';
import useAuth from '../data/useAuth';

import ModalComplete from '../components/ModalComplete';
import { useGlobalContext } from '../components/MyGlobalContext';




const PopoverList: React.FC<{
  onHide: () => void;
}> = ({ onHide }) => (
  <IonList>
    <IonItem><strong>Legend</strong></IonItem>
    <IonItem> <IonIcon icon={busOutline} size="large" color="success" slot="start" /><IonLabel>Delivery</IonLabel></IonItem>
    <IonItem> <IonIcon icon={buildOutline} size="large" color="secondary" slot="start" /><IonLabel>Repair</IonLabel></IonItem>
    <IonItem> <IonIcon icon={flagOutline} size="large" color="danger" slot="start" /><IonLabel>Pickup</IonLabel></IonItem>

    <IonItem><IonBadge color="dark" slot="start">#</IonBadge><IonLabel>Garbage Can Count</IonLabel></IonItem>
    <IonItem><IonBadge color="secondary" slot="start">#</IonBadge><IonLabel>Recycle Can Count</IonLabel></IonItem>
    <IonItem><IonBadge color="success" slot="start">#</IonBadge><IonLabel>Green Can Count</IonLabel></IonItem>
    <IonItem lines="none" detail={false} button onClick={onHide} >
      <IonLabel slot="end"><strong>Close</strong></IonLabel>
    </IonItem>
  </IonList>
);



interface RouteProps extends RouteComponentProps<{ fieldName: string }> { }
//interface GenericProps extends RouteProps { }




const CanList: React.FC = () => {

  const [present] = useIonAlert();

  const authQuery = useQuery("MyCheckAuth", () => IsAppUserAuthenticated(auth));

  const auth = useAuth();
  
  const searchbarRef = useRef<HTMLIonSearchbarElement>(null);


  let history = useHistory();
  //const [present] = useIonAlert();
  const [present2, dismiss] = useIonPopover(PopoverList, { onHide: () => dismiss() });

  const { order, setOrder } = useGlobalContext();
  const { searchText, setSearchText } = useGlobalContext();
  const { flagIn, setFlagIn } = useGlobalContext();

  const [showModalComplete, setShowModalComplete] = useState(false);
  const [recordIndex, setRecordIndex] = useState(0);
  const [recordHot, setRecordHot] = useState(0);


  const [ countPickup, setCountPickup ] = useState(0);
  const [ countRepair, setCountRepair ] = useState(0);
  const [ countDelivery, setCountDelivery] = useState(0);

  const [countPickupA, setCountPickupA] = useState(0);  // all
  const [countPickupR, setCountPickupR] = useState(0);  // Fix
  const [countPickupG, setCountPickupG] = useState(0);  // Green

  const [countRepairA, setCountRepairA] = useState(0);
  const [countRepairR, setCountRepairR] = useState(0);
  const [countRepairG, setCountRepairG] = useState(0);

  const [countDeliveryA, setCountDeliveryA] = useState(0);
  const [countDeliveryR, setCountDeliveryR] = useState(0);
  const [countDeliveryG, setCountDeliveryG] = useState(0);



  const redirect = (props: any) => {
    history.push('/tabs/location/' + props);
  }

  const hotCountQuery = useQuery("GetHotCountQuery", () => GetAppHotCount());
  const requestQuery = useQuery("GetRequestQuery", () => GetAppCanRequestList(order, searchText), {

    onSuccess: (data: any) => {
      if (flagIn === '') {
        setFlagIn('Y');
        setOrder('');
        setSearchText('');
      }

      var repair = 0;
      var repairA = 0;
      var repairR = 0;
      var repairG = 0;
      var pickup = 0;
      var pickupA = 0;
      var pickupR = 0;
      var pickupG = 0;
      var delivery = 0;
      var deliveryA = 0;
      var deliveryR = 0;
      var deliveryG = 0;

      data.map((dataString: any) => {
        if (dataString.ugType === "D")
        {
          delivery = delivery + 1;

          if (dataString.ugfirstcan === 1 ) {
            deliveryA = deliveryA + dataString.ugfirstcan;
          }
          if (dataString.ugothercan === 1) {
            deliveryA = deliveryA + dataString.ugothercan;
          }
          if (dataString.ugrcyclcan === 1) {
            deliveryR = deliveryR + dataString.ugrcyclcan;
          }
          if (dataString.uggreencan === 1) {
            deliveryG = deliveryG + dataString.uggreencan;
          }
        }
        if (dataString.ugType === "F") {
          //console.log(dataString);
          repair = repair + 1;
          if (dataString.ugfirstcan === 1 ) {
            repairA = repairA +  dataString.ugfirstcan;
          }
          if (dataString.ugothercan === 1) {
            repairA = repairA + dataString.ugothercan;
          }
          if (dataString.ugrcyclcan === 1) {
            repairR = repairR + dataString.ugrcyclcan;
          }
          if (dataString.uggreencan === 1) {
            repairG = repairG + dataString.uggreencan;
          }
        }
        if (dataString.ugType === "P") {
          pickup = pickup + 1;
          if (dataString.ugfirstcan === 1) {
            pickupA = pickupA + dataString.ugfirstcan;
          }
          if (dataString.ugothercan === 1) {
            pickupA = pickupA + dataString.ugothercan;
          }
          if (dataString.ugrcyclcan === 1) {
            pickupR = pickupR + dataString.ugrcyclcan;
          }
          if (dataString.uggreencan === 1) {
            pickupG = pickupG + dataString.uggreencan;
          }
        }
      })

      setCountPickup(pickup);
      setCountPickupA(pickupA);
      setCountPickupR(pickupR);
      setCountPickupG(pickupG);

      setCountDelivery(delivery);
      setCountDeliveryA(deliveryA);
      setCountDeliveryR(deliveryR);
      setCountDeliveryG(deliveryG);

      setCountRepair(repair);
      setCountRepairA(repairA);
      setCountRepairR(repairR);
      setCountRepairG(repairG);


      if (searchbarRef.current !== null) {
        searchbarRef.current.setFocus();
      }
    }

  });



  useEffect(() => {
    setOrder(order);
    setSearchText(searchText);
    setCountPickup(0);
    setCountPickupA(0);
    setCountPickupR(0);
    setCountPickupG(0);
    setCountDelivery(0);
    setCountDeliveryA(0);
    setCountDeliveryR(0);
    setCountDeliveryG(0);
    setCountRepair(0);
    setCountRepairA(0);
    setCountRepairR(0);
    setCountRepairG(0);

    requestQuery.refetch();

  }, [order, searchText, showModalComplete]);


  useEffect(() => {
    requestQuery.refetch();

  }, [showModalComplete]);


  //useEffect(() => {

  //  if (searchbarRef.current !== null) {
  //    searchbarRef.current.setFocus();
  //  }

  //}, []);

  


  function CheckKeyEvent(props: any) {
    if (props === 'Enter') {
      requestQuery.refetch();
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle >Request List { } </IonTitle>
          <IonButtons slot="end" color="transparent">
            <IonButton onClick={() => { window.location.href = 'tabs/last'; }}  >
              <IonIcon icon={checkmarkCircleOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/ReportIssue'; }} >
              <IonIcon icon={bugOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/Canlist'; }}>
              <IonIcon icon={returnDownBackOutline} size="large" />
            </IonButton>
          </IonButtons>

        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <IonCard color="warning">
          <IonCardContent>
            <IonItem lines="none" class="ion-no-padding"  color="transparent">
              <IonSearchbar ref={searchbarRef}  showClearButton="always" onIonChange={e => setSearchText(e.detail.value!)} onKeyUp={e => CheckKeyEvent(e.key!)} onIonClear={e => setSearchText("")} />
          </IonItem>
          <IonGrid>
            <IonRow>
                <IonCol style={{borderStyle:'none',borderWitdh:'0px'}} >
                  <IonItem color="transparent">
                <IonLabel>Day</IonLabel>
                 <IonSelect 
                      onIonChange={
                        e => {
                          setSearchText(e.detail.value);
                        }
                      }
                      value={searchText}
                    >
                      <IonSelectOption value="Monday">Mon</IonSelectOption>
                      <IonSelectOption value="Tuesday">Tue</IonSelectOption>
                      <IonSelectOption value="Wednesday">Wed</IonSelectOption>
                      <IonSelectOption value="Thursday">Thur</IonSelectOption>
                      <IonSelectOption value="Friday">Fri</IonSelectOption>                    
                </IonSelect>
                  </IonItem>
                </IonCol>               
                <IonCol style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                  <IonItem color="transparent">
                <IonLabel>Filter/Sort</IonLabel>
                    <IonSelect placeholder="Filter/Sort"
                      onIonChange={e => setOrder(e.detail.value)}
                      value={order}
                    >
                     
                      <IonSelectOption value="Ubaddress">By Address</IonSelectOption>
                      <IonSelectOption value="Ugnumber">By Route</IonSelectOption>
                      <IonSelectOption value="Date">By Order Date</IonSelectOption>
                      <IonSelectOption value="Hot">By Hot</IonSelectOption>
                      
                  </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                  <IonButton expand="full" onClick={() => {
                    setSearchText(''); setOrder('');
                    if (searchbarRef.current !== null) {
                      searchbarRef.current.setFocus();
                    } }}>
                    Show All
                    </IonButton>
                </IonCol>
            </IonRow>
            </IonGrid>
            <IonItem lines="none" class="ion-no-padding" color="transparent">
              <IonGrid>
                <IonRow>
                  <IonCol onClick={(e) => { setOrder("Delivery Only"); }} style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                    <IonIcon icon={busOutline} size="large" color="success" /> {countDelivery}
                  </IonCol>
                  <IonCol className="CenterAlignment" onClick={(e) => { setOrder("Fix Only"); }} style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                    <IonIcon icon={buildOutline} size="large" color="primary" /> {countRepair}
                  </IonCol>
                  <IonCol className="RightAlignment" onClick={(e) => { setOrder("Pickup Only"); }} style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                    <IonIcon icon={flagOutline} size="large" color="danger" />{countPickup}
                  </IonCol>
                </IonRow>     
                <IonRow>
                  <IonCol className="CenterAlignment"
                    onClick={(e) =>
                      present2({
                        event: e.nativeEvent,
                      })
                    }
                    style={{ borderStyle: 'none', borderWitdh: '0px' }}
                  >
                    <IonBadge color="dark">{countDeliveryA}</IonBadge>&nbsp;
                    <IonBadge color="secondary">{countDeliveryR}</IonBadge>&nbsp;
                    <IonBadge color="success" >{countDeliveryG}</IonBadge>
                  </IonCol>
                  
                  <IonCol className="CenterAlignment"
                    style={{ borderStyle: 'none', borderWitdh: '0px' }}
                    onClick={(e) =>
                    present2({
                      event: e.nativeEvent,
                    })
                  }>
                    <IonBadge color="dark">{countRepairA}</IonBadge>&nbsp;
                    <IonBadge color="secondary">{countRepairR}</IonBadge>&nbsp;
                    <IonBadge color="success">{countRepairG}</IonBadge>
                  </IonCol>
                  <IonCol className="CenterAlignment"
                    style={{ borderStyle: 'none', borderWitdh: '0px' }}
                    onClick={(e) =>
                    present2({
                      event: e.nativeEvent,
                    })
                  }>
                    <IonBadge color="dark">{countPickupA}</IonBadge>&nbsp;
                    <IonBadge color="secondary">{countPickupR}</IonBadge>&nbsp;
                    <IonBadge color="success">{countPickupG}</IonBadge>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>

          </IonCardContent>
        </IonCard>


        <div>
          {requestQuery.isLoading && (
            <IonCard>
              <IonCardContent>
                <IonItem>
                  Loading...
                </IonItem>
              </IonCardContent>
            </IonCard>
          )}
          {
            requestQuery.isSuccess && requestQuery.data && requestQuery.data.length > 0 && requestQuery.data.map((dataString: any) =>
            (
              <IonCard key={dataString.ugautoidx}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    <IonItem href="javascript:void(0);">
                      
                      {dataString.ugType === "D" && (<div><IonIcon slot="start" icon={busOutline} size="large" color="success" /> Delivery </div>)}
                      {dataString.ugType === "F" && (<div><IonIcon slot="start" icon={buildOutline} size="large" color="primary" /> Fix/Repair </div>)}
                      {dataString.ugType === "P" && (<div><IonIcon slot="start" icon={flagOutline} size="large" color="danger" /> Pickup </div>)}
                      {dataString.ugType === "I" && (<div><IonIcon slot="start" icon={flagOutline} size="large" color="warning" /> Inventory </div>)}
                      {/*code starts here*/}
                      {dataString.ughotcust === 1 && (<IonIcon slot="start" size="large" src='/assets/icon/hot.svg' />)}
                      <IonLabel slot="end"
                        onClick={() => window.location.href = "https://www.google.com/maps/search/?api=1&query=" + dataString.ugAddress + " Orem, UT"}>
                      <IonIcon icon={navigateCircleOutline} size="large" color="primary"  />
                      </IonLabel>
                    </IonItem>
                  </IonCardSubtitle>
                  <IonCardTitle>  &nbsp;&nbsp;{dataString.ugseq}. &nbsp; {dataString.ugAddress} </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem href="javascript:void(0);"  lines="none" onClick={() => {
                    setRecordIndex(dataString.ugautoidx);
                    setRecordHot(dataString.ughotcust);
                    setShowModalComplete(true);
                  }
                  }>
                    <IonText>
                      Account #: {dataString.ugnumber} <br />
                      Requested Date: {dataString.ugdateadd} <br />
                      Work-Order Date: <strong>{dataString.ugweekday} </strong> <br />
                      {/*{dataString.ugautoidx} | {dataString.ugnumber} | {dataString.uggislat}  {dataString.uggislng}<br />*/}
                      <h5>*{dataString.ugdesc}</h5><br />
                      <IonBadge color="dark">{dataString.ugfirstcan + dataString.ugothercan}</IonBadge>&nbsp;
                      <IonBadge color="secondary">{dataString.ugrcyclcan}</IonBadge>&nbsp;
                      <IonBadge color="success">{dataString.uggreencan}</IonBadge>                   
                      <IonBadge mode="ios" color="light">{dataString.ugElapsed} business day(s)</IonBadge>
                      {dataString.ugordercount != "1" &&
                        (<IonBadge color="warning">{dataString.ugordercount}</IonBadge>)
                      }
                      
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))
          }
        </div>



        <IonModal isOpen={showModalComplete} backdropDismiss={false} onDidDismiss={() => { setShowModalComplete(false); }}>
          <ModalComplete
            id={recordIndex}
            ughotcust={recordHot}
            setShowModalComplete={setShowModalComplete} ></ModalComplete>
        </IonModal>

        {hotCountQuery.isSuccess && hotCountQuery.data > 0 && (
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton onClick={() => {
              setSearchText('');
              setOrder('Hot');
            }}>
              <IonItemGroup>
               <IonIcon size="large" src='/assets/icon/hot.svg'></IonIcon>
               <IonCardSubtitle color="white">{hotCountQuery.data}</IonCardSubtitle>
              </IonItemGroup>
              
            </IonFabButton>
          </IonFab>
        )}
        

      </IonContent>
    </IonPage>
  )
}

export default CanList;
