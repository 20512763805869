import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonMenuButton, IonPage, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { bookOutline, bugOutline, calendarOutline, checkmarkCircleOutline, pricetagOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Redirect, useHistory } from 'react-router';
import { AppReportResponse, GetAppIssueList } from '../data/api';
import './Generic.css';


const ReportIssue: React.FC = () => {

  const history = useHistory();
  const [present] = useIonAlert();
  const [showNotes, setShowNotes] = useState<string>("");


  const historyQuery = useQuery("GetIssueHistoryQuery", () => GetAppIssueList());

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle >Report Issues</IonTitle>
          <IonButtons slot="end"  color="transparent">
            <IonButton  onClick={() => { window.location.href = 'tabs/last'; }} >
              <IonIcon icon={checkmarkCircleOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/Canlist'; }}>
              <IonIcon icon={returnDownBackOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <IonCard color="warning">
          <IonCardHeader>
            <IonText>
              If you encounter any concerns or have any suggestions, please document them below.
              This way, we can maintain a comprehensive record of any issues and provide you with prompt
              responses. <br></br>
              Additionally you can also contact me by<br></br><br></br>
              Phone: 801-229-7179<br></br>
              Email: rwang@orem.gov
            </IonText>
          </IonCardHeader>
        </IonCard>
        
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Describe the issue below and then click [Submit] button below.</IonCardTitle>
            <IonItemDivider></IonItemDivider>
          </IonCardHeader>
          <IonCardContent>
            <IonItem mode="md" style={{ paddingTop: '5px' }} >
              <IonLabel position="floating"></IonLabel>
              <IonTextarea
                rows={4} // Number of visible rows
                wrap="soft" // Wrap text within the textarea
                onIonChange={
                  (e) => setShowNotes(e.detail.value!)
                }
              />
            </IonItem>
            <IonButton expand="full" onClick={async () => {
              // Process the request
              const response = {
                type: 'R',
                notes: showNotes
              }

              const returnValue = await AppReportResponse(response);
              if (returnValue === "success") {

                present({
                  header: "Attention!",
                  message: "The request is successfully submitted!",
                  buttons: [
                    {
                      text: "Ok", handler: async () => {
                        present("Your input has successfull been recorded.");
                      }
                    }
                  ]
                });
             
              }
              else {
                present("Sorry that we are unable to process your request to the following error:" + returnValue);
              }

              historyQuery.refetch();
              history.push('/tabs/ReportIssue');

            }} >Submit</IonButton>
          </IonCardContent>
        </IonCard>
        <p></p>
        <IonCard>
          <IonCardHeader color="lightblue">
            <IonCardTitle>List of Issues</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div style={{ paddingTop:'20px' }}></div>
            {historyQuery.isSuccess && historyQuery.data && historyQuery.data.length > 0 && historyQuery.data.map((dataString: any) =>
            (

              <IonCard key={dataString.Isuidx} className="ion-padding-top">
                <IonCardHeader color={dataString.isusts === "Pending" ? "lightpink" : "lightgreen"} >
                  <IonItem color="transparent">
                    <IonIcon slot="start" icon={pricetagOutline} size="large" />
                    <IonCardSubtitle slot="start">Timestamp: {dataString.isustmp}</IonCardSubtitle>
                    <IonCardSubtitle slot="end">Status: {dataString.isusts}</IonCardSubtitle>
                  </IonItem>

                  

                </IonCardHeader>
                <IonCardContent>
                  <IonItem className="item-text-wrap">
                    <IonText className="item-text-wrap"><strong>Description:</strong> {dataString.isucomm}</IonText>
                  </IonItem>
                  {dataString.Isuresp !== "" && (
                    <IonItem lines="none" className="item-text-wrap">
                      <IonText><strong>Response:</strong> {dataString.isuresp}</IonText>
                    </IonItem>
                  )}
                </IonCardContent>
              </IonCard>
            )
            )}
          </IonCardContent>
        </IonCard>

       
      

      </IonContent>
    </IonPage>
  )
}

export default ReportIssue;
