import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { Formik } from 'formik';
import { arrowForwardCircleOutline, bugOutline, buildOutline, busOutline, calculatorOutline, calendarOutline, checkmarkCircleOutline, flagOutline, pricetagOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Camera from '../components/Camera';
import { GetAppTodayList, UpdateAppGPSPositions } from '../data/api';
import { formatDateYYYYMMDD, getFormattedDate } from '../data/utilities';


const Last: React.FC = (props) => {

  const [countPickup, setCountPickup] = useState(0);
  const [countRepair, setCountRepair] = useState(0);
  const [countDelivery, setCountDelivery] = useState(0);

  const [showToday, setShowToday] = useState(formatDateYYYYMMDD(new Date()));
  const todayQuery = useQuery("GetTodayOrderQuery", () => GetAppTodayList(showToday), {

    onSuccess: (data: any) => {

      var repair = 0;
      var pickup = 0;
      var delivery = 0;

      data.map((dataString: any) => {
        if (dataString.ugType === "D") {
          delivery = delivery + 1;
        }

       
        if (dataString.ugType === "P") {
          pickup = pickup + 1;
        }

        if (dataString.ugType === "F") {
          repair = repair + 1;
        }
       
      })

      setCountPickup(pickup);
      setCountDelivery(delivery);
      setCountRepair(repair);
    }

  });

  



  useEffect(() => {
    todayQuery.refetch();
  }, [showToday]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle >Tickets Processed</IonTitle>
          <IonButtons slot="end" color="transparent">
            <IonButton  onClick={() => { window.location.href = 'tabs/last'; }} >
              <IonIcon icon={checkmarkCircleOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/ReportIssue'; }} >
              <IonIcon icon={bugOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/Canlist'; }}>
              <IonIcon icon={returnDownBackOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonCard >
          <IonCardHeader color="warning">
            <IonItem color="transparent">    
              <IonLabel slot="start">Select</IonLabel>
              <IonIcon slot="end" icon={calendarOutline} size="large" color="primary" />
              <IonDatetime slot="end" value={showToday} onIonChange={async (e) => { await setShowToday(e.detail.value!); }} ></IonDatetime>
            </IonItem>
            <IonItem lines="none" class="ion-no-padding" color="transparent">
              <IonGrid>
                <IonRow>
                  <IonCol  style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                    <IonIcon icon={busOutline} size="large" color="success" /> {countDelivery}
                  </IonCol>
                  <IonCol className="CenterAlignment" style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                    <IonIcon icon={buildOutline} size="large" color="primary" /> {countRepair}
                  </IonCol>
                  <IonCol className="RightAlignment" style={{ borderStyle: 'none', borderWitdh: '0px' }}>
                    <IonIcon icon={flagOutline} size="large" color="danger" />{countPickup}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonCardHeader>
          <IonCardContent>
            <div style={{ height:'20px' }}></div>
            {todayQuery.isSuccess && todayQuery.data && todayQuery.data.length > 0 && todayQuery.data.map((dataString: any) =>
            (
              <IonCard key={dataString.ugautoidx}>
                <IonCardHeader color={dataString.ugwmnotes ==="Order completed" ? "lightgreen" : "lightpink" } >
                  <IonItem color="transparent">
                    {dataString.ughotcust === 1 && (<IonIcon slot="start" size="large" src='/assets/icon/hot.svg' />)}
                    <IonIcon slot="start" icon={pricetagOutline} size="large" />
                    <IonCardTitle slot="start"># {dataString.ugseq}</IonCardTitle>
                    <IonCardSubtitle slot="end">Timestamp: {dataString.ugdatedel} {dataString.ugtimedel}</IonCardSubtitle>
                  </IonItem>
                 
                  <IonItem color="transparent" lines="none">
                    <IonCardTitle slot="start"> {dataString.ugAddress}</IonCardTitle>
                    {dataString.ugType === "D" && (<div slot="end"><IonIcon icon={busOutline} size="large" color="success" /> Delivery </div>)}
                    {dataString.ugType === "F" && (<div slot="end"><IonIcon icon={buildOutline} size="large" color="primary" /> Fix/Repair </div>)}
                    {dataString.ugType === "P" && (<div slot="end"><IonIcon icon={flagOutline} size="large" color="danger" /> Pickup </div>)}
                    {dataString.ugType === "I" && (<div slot="end"><IonIcon icon={flagOutline} size="large" color="warning" /> Inventory </div>)}
                  </IonItem>
                 
                </IonCardHeader>
                  <IonCardContent>
                  <IonItem lines="none">
                    <IonLabel slot="start">Ticket Status</IonLabel>
                    <IonLabel slot="end"> {dataString.ugwmnotes}</IonLabel>
                  </IonItem>
                  {dataString.ugdesc !== "" && (
                    <IonItem lines="none">
                      <IonText slot="end"> {dataString.ugdesc}</IonText>
                    </IonItem>
                  )}
                  </IonCardContent>
              </IonCard>
             
            ))}

          </IonCardContent>
        </IonCard>


      </IonContent>
    </IonPage>
  )
}



export default Last;
