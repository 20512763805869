import React from 'react';
import { useLocation } from 'react-router';
import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { moonOutline, homeOutline,  exitOutline, busOutline, buildOutline, flagOutline, bugOutline } from 'ionicons/icons';
import './Menu.css'
import useAuth from '../data/useAuth';
import { LogoutApp } from '../data/api';


const routes = {
  appPages: [
    { title: 'Home', path: '/tabs/canlist', icon: homeOutline },
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}

interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: any
}

interface MenuProps extends StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, setDarkMode }) => {
  const location = useLocation();
  const { user, setUser } = useAuth();
  const loggedIn: boolean = user ? true : false;

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          { p.title.indexOf("Reservation")>0 ?
            (
              <IonItem  href={p.path}  className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
              <IonIcon slot="start" icon={p.icon} />
              <IonLabel>{p.title}</IonLabel>
            </IonItem>
            ) :
            (
              <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined} >
                <IonIcon slot="start" icon={p.icon} />
                <IonLabel>{p.title}</IonLabel>
              </IonItem>
            )
          }
        </IonMenuToggle>
      ));
  }

  function ExistApp() {
    setUser(false);
    LogoutApp();
  }

  return (

    <IonMenu  type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonItem>
            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
              <IonImg src="/assets/icon/App0.png" style={{ filter: darkMode ? "invert(1) hue-rotate(180deg)" : "" }} />
            </div>
          </IonItem>
        </IonList>
        <IonList lines="none">
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
           <IonListHeader>Settings</IonListHeader>
          <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
        </IonList>
        {loggedIn && (
          <>
            <IonList lines="none">
              <IonListHeader>STAFF ONLY</IonListHeader>
              {/*<IonItem href="tabs/ReportIssue">*/}
              {/*  <IonIcon slot="start" icon={bugOutline} />*/}
              {/*  <IonLabel>Report Issues</IonLabel>*/}
              {/*</IonItem>*/}
              <IonItem href="/" onClick={() => { ExistApp(); }}>
                <IonIcon slot="start" icon={exitOutline} />
                <IonLabel>Log Out</IonLabel>
              </IonItem>
            </IonList>
            <IonList lines="none">
              <IonListHeader>Legend</IonListHeader>
              <IonItem>
                <IonIcon slot="start" src='/assets/icon/hot.svg' />
                <IonLabel>Hot Ticket</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={busOutline} color="success"></IonIcon>
                <IonLabel>Delivery</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={buildOutline} color="primary"></IonIcon>
                <IonLabel>Repair</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={flagOutline} color="danger"></IonIcon>
                <IonLabel>Pickup</IonLabel>
              </IonItem>
            </IonList>
          </>
        )}

        {/*<IonList lines="none">*/}
        {/*  <IonListHeader>STAFF ONLY</IonListHeader>*/}
        {/*  {loggedIn ? (*/}
        {/*    <>*/}
        {/*    <IonItem href="/" onClick={() => { ExistApp(); }}>*/}
        {/*    <IonIcon slot="start" icon={exitOutline} />*/}
        {/*    <IonLabel>Log Out</IonLabel>*/}
        {/*    </IonItem>*/}

        {/*    </>*/}
        {/*     ) : (<IonItem href="./tabs/canlist">*/}
        {/*    <IonIcon slot="start" icon={personCircleOutline}></IonIcon>*/}
        {/*    <IonLabel>Login</IonLabel>*/}
        {/*  </IonItem>)}*/}
        {/*</IonList>*/}


      </IonContent>
    </IonMenu>

  );
};

export default Menu;
