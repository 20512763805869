import axios from 'axios';


function unauthorize(err, setUser) {
  //console.log(err);
  if (err.response.status === 404 || err.response.status === 401) {
    setUser(null);
    window.location.href = '/tabs/login';
  }
}

export async function IsUserAuthenticated() {
  return axios
    .get('/api/IdentityAuthenticated')
    .then(res => res.data)
}



export async function LogoutApp() {
  return axios
    .get("/api/LogoutServer")
    .then(res => res.data);
}

export async function ValidateUser(valEmail, valPassword) {
  return axios
    .post('/api/ValidateUser', { UserEmail: valEmail, UserPassword: valPassword })
    .then(res => res.data)
}



export async function GetAppAccessList(valDept) {
  return axios
    .get("/api/GetAccessList")
    .then(res => res.data);
}


export async function IsAppUserAuthenticated(auth) {
  return axios
    .get("/api/IsUserAuthenticated")
    .then(res => res)
    .catch(err => { unauthorize(err, auth.setUser); window.location.href = '/tabs/login'; })
}


export async function GetAppCanRequestList(byValue, searchValue) {
  return axios
    .get("/api/GetCanRequestList?orderby=" + byValue + "&keyword=" + searchValue )
    .then(res => res.data);
}

export async function GetAppCanRequestListH(byValue, searchValue) {
  return axios
    .get("/api/GetCanRequestListH?orderby=" + byValue + "&keyword=" + searchValue)
    .then(res => res.data);
}

export async function UpdateAppGPSPositions() {
  return axios
    .get("/api/about")
    .then(res => res)
}

export async function GetAppCanRequestDetail(indexValue) {
  return axios
    .get("/api/GetCanRequestDetail?index=" + indexValue)
    .then(res => res.data);
}
export async function UpdateAppGarbageRequest(valautoindex, valtype, valnotes, valcan1, valcan3, valcan4) {
  return axios
    .post('/api/UpdateGarbageRequest', {
      autoindex: valautoindex,
      type: valtype,
      notes: valnotes,
      can1: valcan1,
      can3: valcan3,
      can4: valcan4
    })
    .then(res => res.data)
}

export async function UploadAssociatedDocX(valData) {
  return axios
    .post("/api/UploadDocX", valData, {
      headers: {
        'Content-Type': 'multipart/form-data or application/x-www-form-urlencoded'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}

export async function GetAppAttachmentList(valIndex) {
  return axios
    .get("/api/GetAttachmentList?index=" + valIndex)
    .then(res => res.data);
}

export async function GetAppUserEmail() {
  return axios
    .get("/api/GetUserEmail")
    .then(res => res.data);
}

export async function EmailAppResponse(valEmail, valOrder, valKeyword) {
  //    const data = {
  //      email: valEmail,
  //      type: valType,
  //      dateday: valDateday
  //};
  //console.log(JSON.stringify(data));
  return axios
    .post('/api/RequestList', {
      email: valEmail,
      order: valOrder,
      keyword: valKeyword
    })
    .then(res => res.data)
}

export async function AppReportResponse(data) {
  var config = {
    method: 'post',
    url: '/api/UpdateIssue',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return await axios(config)
    .then(res => res.data)
}

export async function GetAppMultipleOrderList(valIndex) {
  return axios
    .get("/api/GetSameAddressOrderList?index=" + valIndex)
    .then(res => res.data);
}


export async function GetAppPrintoutDate() {
  return axios
    .get("/api/GetPrintoutDate")
    .then(res => res.data);
}

export async function GetAppPrintoutList(dateValue) {
  return axios
    .get("/api/GetPrintoutList?date=" + dateValue)
    .then(res => res.data);
}


export async function GetAppTodayList(date) {
  if (date === undefined) date = ""
  return axios
    .get("/api/gettodaylist/" + date)
    .then(res => res.data);
}

export async function GetAppIssueList() {
  return axios
    .get("/api/getissuelist")
    .then(res => res.data);
}

export async function setAppHotRequest(valautoindex, valhot) {
  return axios
    .post('/api/SetHotRequest', {
      autoindex: valautoindex,
      hot: valhot
    })
    .then(res => res.data)
}


export async function GetAppHotCount() {
  return axios
    .get("/api/GetHotCount")
    .then(res => res.data);
}

