import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonItem, IonMenuButton, IonPage,  IonTitle, IonToolbar } from '@ionic/react';
import {  bugOutline, checkmarkCircleOutline, returnDownBackOutline, syncOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import GoogleMapReact from 'google-map-react';
import { GetAppCanRequestList, UpdateAppGPSPositions } from '../data/api';
import Marker from './Marker';
import { useGlobalContext } from '../components/MyGlobalContext';



const Map: React.FC = () => {

  const { order, setOrder } = useGlobalContext();
  const { searchText, setSearchText } = useGlobalContext();
  const [done, setDone] = useState<boolean>(false);
  const [refreshQuery, setRefreshQuery] = useState<string>("N");
  const[random, setRandom] = useState<number>(0);

  

  const requestQuery = useQuery("GetRequestQuery" + random, async () => GetAppCanRequestList(order, searchText), {
      onSuccess: (data: any) => {
      setDone(true);
      }
    }
    );

  useEffect(() => {
     setRandom(Math.floor(Math.random() * (10 - 99 + 1)) + 10);
     requestQuery.refetch();
  }, [order, searchText, refreshQuery]);



  const defaultProps = {
    center: {
      lat: 40.29904,
      lng: -111.69430
    },
    zoom: 13
  };




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          

          <IonTitle>Map</IonTitle>

        

          <IonButtons slot="end" color="transparent">

            <IonButton  onClick={() => { window.location.href = 'tabs/Last'; }} >
              <IonIcon icon={checkmarkCircleOutline} size="large" />
            </IonButton>
            <IonButton  onClick={() => { setRefreshQuery("Y") }}>
              <IonIcon icon={syncOutline} size="large" />
            </IonButton>

            <IonButton  onClick={() => { window.location.href = 'tabs/ReportIssue'; }} >
              <IonIcon icon={bugOutline} size="large" />
            </IonButton>

            <IonButton  onClick={() => { window.location.href = 'tabs/canlist'; }} >
              <IonIcon icon={returnDownBackOutline} size="large" />
            </IonButton>
          </IonButtons>

        </IonToolbar>
      </IonHeader>
      <IonContent>

        {requestQuery.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem>
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
           
          >
            {
              done && requestQuery.isSuccess && requestQuery.data && requestQuery.data.length > 0 && requestQuery.data.map((dataString: any) => (
                <Marker 
                  id={dataString.ugautoidx}
                  lat={dataString.uggislat}
                  lng={dataString.uggislng}
                  name={dataString.ugAddress}
                  type={dataString.ughotcust === 1 ? "H" : dataString.ugType}
                  label={dataString.ugordercount}
                  index={dataString.ugindex}     
                  key={dataString.ugautoidx}
                  ughotcust={dataString.ughotcust}
                  refreshQuery={refreshQuery}
                  setRefreshQuery={setRefreshQuery}
                  />
   
              )
              )
            }

          </GoogleMapReact>
        </div>

      </IonContent>
    </IonPage>
  )
}

export default Map;
