import React, { useState, useRef } from 'react';
import { IonContent, IonPage, IonItem,  IonInput, IonButton, IonLoading, IonGrid, IonRow, IonCol, useIonAlert, useIonViewDidEnter, IonIcon, IonToggle, IonLabel } from '@ionic/react';
import { Formik } from 'formik';
import { LoginSchema } from '../data/validations';
import './Login.css';
import { ValidateUser, UpdateAppGPSPositions } from '../data/api';
import Cookies from 'universal-cookie/es6';
import { useQuery } from 'react-query';
import { getDayofWeekDesc } from '../data/utilities.js';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';


interface OwnProps { setUser: any }
interface LoginProps extends OwnProps { }

const Login: React.FC<LoginProps> = ({ setUser }) => {

  const cookies = new Cookies();



  const UpdateGPSQuery = useQuery("UpdateGPSQuery", () => UpdateAppGPSPositions());

  const myRef1 = useRef<HTMLIonInputElement>(null);
  const myRef2 = useRef<HTMLIonInputElement>(null);

  const [userEmail] = useState(cookies.get('UserId'));
  const [present] = useIonAlert();
  const [showWaiting, setShowWaiting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  useIonViewDidEnter(() => {

    UpdateGPSQuery.refetch();

    if (myRef1.current != null) {
      if (myRef1.current.value === null) {
        myRef1.current && myRef1.current.setFocus();
      }
      else {
        myRef2.current && myRef2.current.setFocus();
      }
    }  
  },[]);


  return (
    <IonPage>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showWaiting}
        onDidDismiss={() => setShowWaiting(false)}
        message={'Please wait...'}
        duration={5000}
      />
      <IonContent >
        <div className="Height120"></div>


        <Formik
          initialValues={{
            email: userEmail,
            password: ""
          }}

          validationSchema={LoginSchema}
          onSubmit={async values => {

            setShowWaiting(true);
            await new Promise(resolve => setTimeout(resolve, 200));

            const data = await ValidateUser(values.email, values.password);
            if (data !== '') {
              setUser(data);
              

              window.location.href = '/tabs/canlist';
              // window.Location = '/tabs/canlist';
              //Cause to refresh the logout button
              // window.location.reload(false);
            }
            else {
              present('Sorry that either your email or your password is incorrect or you may not have the right to access this program!' + data, [{ text: 'Ok' }])

            }
          }}
        >

          {(formikProps: any) => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ paddingBottom: "40px" }}
            >

          <IonGrid>
                <IonRow justify-content-center color="white">
            <IonCol size="12" className="login-title">
              <div text-center>
                Login
              </div>
                  </IonCol>
            <IonCol size="12" className="login-colpadding">
                    <IonItem color="transparent" className="login-input">
                      <IonInput
                        type="text"
                        name="email"
                        ref={myRef1}
                        placeholder="your@orem.gov"
                        onIonChange={formikProps.handleChange}
                        value={formikProps.values.email}
                        required={true}
                      />
                    </IonItem>
                  </IonCol>

            <IonCol size="12" className="login-colpadding">
                    <IonItem color="transparent" className="login-input">
                      <IonInput
                        type={showPassword ? "text" : "password"}
                        name="password"
                        ref={myRef2}
                        placeholder="User password"
                        onIonChange={formikProps.handleChange}
                        value={formikProps.values.password}
                        required={true}
                      />
                      <div onClick={() =>
                        setShowPassword(!showPassword)
                        }>
                        <IonIcon icon={!showPassword ? eyeOffOutline : eyeOutline} slot="end" />
                        </div>
                    </IonItem> 
                  </IonCol>
                 
            <div className="login-button-colpadding">
                    <br></br>
                  </div>

            <IonCol size="12" className="login-button-colpadding">
                    <IonItem color="transparent" lines="none" >
                      <IonButton 
                        size="large"
                        expand="block"
                        className="login-button"
                        type="submit"
                      >Submit</IonButton>
                    </IonItem>
                  </IonCol>
          </IonRow>

              </IonGrid>
            </form>
          )}
          </Formik>
      </IonContent>
    </IonPage>
  );
}

export default Login;
