import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonRow, IonSegment, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { bodyOutline, bookOutline, bugOutline, calendarOutline, callOutline, chatboxEllipsesOutline, checkmarkCircle, checkmarkCircleOutline, constructOutline, homeOutline, locationOutline, personOutline, receiptOutline, returnDownBackOutline, trashOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import ModalCalendar from '../components/ModalCalendar';
//import "react-modern-calendar-datepicker/lib/DatePicker.css";
//import DatePicker from "react-modern-calendar-datepicker";
import './Generic.css';
import './Test.css';
import { GetAppPrintoutDate, GetAppPrintoutList } from '../data/api';
import { useQuery } from 'react-query';
import { shortDate } from '../data/utilities'


const Printout: React.FC = (props) => {

  const [showDate, setShowDate] = useState(null);
  const dateQuery = useQuery("MyDates", () => GetAppPrintoutDate());

  const dateListQuery = useQuery("MyDateList", () => GetAppPrintoutList(showDate));
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    dateListQuery.refetch();
  }, [showDate]);



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Print Date</IonTitle>
          <IonButtons slot="end"  color="transparent">
            <IonButton onClick={() => { window.location.href = 'tabs/last'; }} >
              <IonIcon icon={checkmarkCircleOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/ReportIssue'; }} >
              <IonIcon icon={bugOutline} size="large" />
            </IonButton>
            <IonButton onClick={() => { window.location.href = 'tabs/Canlist'; }}>
              <IonIcon icon={returnDownBackOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard color="warning">
          <IonCardContent>
            <IonItem lines="none" class="ion-no-padding" color="transparent">
              <IonIcon icon={bookOutline} color="dark" size="large" slot="start" /> &nbsp;
              <IonLabel class="ion-text-wrap">
                From here you can view all the requested printout list.
              </IonLabel>   
            </IonItem>
            <IonItem color="transparent">
              <IonLabel>*Printed Date</IonLabel>
              <IonSelect
                onIonChange={async (e: any) => {
                  setShowDate(e.detail.value);
                }}

              >
                {dateQuery.isSuccess && dateQuery.data && dateQuery.data.length > 0 && dateQuery.data.map((dataString: any) =>
                (
                  <>
                    <IonSelectOption value={dataString} > {dataString}</IonSelectOption>
                  </>
                ))
                }
              </IonSelect>
             
            </IonItem>
            </IonCardContent>
        </IonCard>
        {dateListQuery.isLoading && (
          <IonCard key="110" color="transparent">
            <IonCardContent>
              <IonItem>
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        <IonList>
          {
            dateListQuery.isSuccess && dateListQuery.data && dateListQuery.data.length > 0 && dateListQuery.data.map((dataString: any) =>
            (
              <>
                <IonItem color={dataString.ugcolor === "Y" ? 'lightgreen' : 'white'}
                  href="javascript:void(0);"
                  key={dataString.ugautoidx}
                >

                  <IonLabel
                    class="ion-text-wrap"
                    mode="ios"
                    style={{
                      borderLeft: '2px solid',
                      borderLeftWidth: '1px',
                      marginBottom: "5px",
                      paddingTop: '5px',
                      borderLeftColor: 'dark',
                      paddingLeft: '20px'
                    }}>
                    <div style={{ paddingBottom: '0px' }}>
                      <h1>
                        {dataString.ugsequence}. {dataString.ugaddress} - # {dataString.ugnumber}
                      </h1> 
                    </div>

                    <div className="hr"> </div>
                    <IonGrid>
                      <IonRow>
                          <IonCol>
                          <IonIcon icon={personOutline} style={{ fontSize: '25px' }}  />&nbsp;&nbsp;{dataString.ugname}
                        </IonCol>
                        <IonCol>
                          <IonIcon icon={callOutline} style={{ fontSize: '25px' }}  />&nbsp;&nbsp;{dataString.ugphone}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonIcon icon={trashOutline} style={{ fontSize: '25px' }} color="dark" />&nbsp;&nbsp;{dataString.ugfirstcan}
                          <IonIcon icon={trashOutline} style={{ fontSize: '25px' }} color="dark" />&nbsp;&nbsp;{dataString.ugothercan}
                          <IonIcon icon={trashOutline} style={{ fontSize: '25px' }} color="secondary" />&nbsp;&nbsp;{dataString.ugrcyclcan}
                          <IonIcon icon={trashOutline} style={{ fontSize: '25px' }} color="success" />&nbsp;&nbsp;{dataString.uggreencan}
                        </IonCol>
                        <IonCol>
                          <IonIcon icon={constructOutline} style={{ fontSize: '25px' }} />&nbsp;&nbsp;{dataString.ugaction}
                        </IonCol>
                      </IonRow>
                      <IonRow> 
                        <IonCol>
                          <IonIcon icon={chatboxEllipsesOutline} style={{ fontSize: '25px' }} />&nbsp;&nbsp;
                          {dataString.ugdesc}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonIcon icon={checkmarkCircle} style={{ fontSize: '25px' }} color="success" />&nbsp;&nbsp;
                          {dataString.ugwmnotes}
                        </IonCol>
                        <IonCol>
                          <IonIcon icon={bodyOutline} style={{ fontSize: '25px' }}  />&nbsp;&nbsp;
                          {dataString.ugwmuser}
                        </IonCol>
                      </IonRow>
                      </IonGrid>
                  </IonLabel>
                </IonItem>
              </>
            )
            )
          }
        </IonList>



      </IonContent>
    </IonPage>
  )
}



export default Printout;
