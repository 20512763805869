import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonItem,  IonMenuButton,  IonPage,  IonTitle,  IonToolbar } from '@ionic/react';
import { returnDownBackOutline } from 'ionicons/icons';
import React from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import Marker from './Marker';
import { GetAppCanRequestDetail } from '../data/api';
import GoogleMapReact from 'google-map-react';



//interface Componant {
//  text: string,
//  lat: number,
//  lng: number
//}

/*const AnyReactComponent: React.FC<Componant> = ({ text, lat, lng }) => <div>{text}</div>;*/
//const AnyReactComponent = ({ text }) => <div>{text}</div>;

interface RouteProps extends RouteComponentProps<{ index : any }> { }
interface GenericProps extends RouteProps { }


const Location: React.FC<GenericProps> = ({ match }) => {


  // console.log(match.params.index);
  //const [valLat, setValLat] = useState(0);
  //const [valLng, setValLng] = useState(0);

  //var { latvalue, lngvalue } = useParams();

  //useEffect(() => {
  //  setValLat (latvalue);
  //  setValLng (lngvalue);
  //},[])



  const requestQuery = useQuery("GetRequestDetailQuery", () => GetAppCanRequestDetail(match.params.index));


  const defaultProps = {
    center: {
      lat: 40.29904,
      lng: -111.69430
    },
    zoom: 13
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Service Location</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/canlist' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        {requestQuery.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem>
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        {
          requestQuery.isSuccess && requestQuery.data && (
           
            /*bootstrapURLKeys = {{key: "AIzaSyCC50sp76yvk4Ugu0CGooswSt53kN1ZlAU" }}*/

              <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                >

                <Marker
                  lat={requestQuery.data.uggislat}
                  lng={requestQuery.data.uggislng}
                  name={requestQuery.data.ugAddress}
                  type={requestQuery.data.ugType}
                  />

                </GoogleMapReact>
              </div>
          )
        } 

      </IonContent>
    </IonPage>
  )
}

export default Location;
