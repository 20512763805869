
import React, { useEffect, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel,  IonButton, IonIcon,  IonInput, IonButtons, IonCardHeader,  IonItemDivider, IonSelect, IonSelectOption, IonBadge, IonItemGroup, useIonLoading, IonCardTitle, IonFooter, IonToggle } from '@ionic/react';
import { Formik } from 'formik';
import { chevronDownCircleOutline, chevronForwardCircleOutline, closeOutline,  navigateCircleOutline } from 'ionicons/icons';
import {  useQuery } from 'react-query';
import { GetAppCanRequestDetail, setAppHotRequest, UpdateAppGarbageRequest } from '../data/api';
import './Generic.css';




interface ModalCompleteProps {
  id: any,
  ughotcust :any,
  setShowModalComplete: any
}

const ModalComplete: React.FC<ModalCompleteProps> = ({ id, ughotcust, setShowModalComplete }) => {

  let recordIndex = id;
  const requestQuery = useQuery("GetRequestDetailQuery", () => GetAppCanRequestDetail(id));


  return (<>
    <IonHeader >
      <IonToolbar color="warning">
        <IonTitle color="primary">Complete the Task</IonTitle>
        <IonButtons slot="end" onClick={() => { setShowModalComplete(false); }} color="transparent">

          <IonButton  onClick={() => window.location.href = "https://www.google.com/maps/search/?api=1&query=" + requestQuery.data.ugAddress + " Orem, UT"}>
            <IonIcon icon={navigateCircleOutline} size="large" color="primary" />
          </IonButton>

          <IonButton slot="end">
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">

      {requestQuery.isLoading && (
        <IonItem>
          Loading...
        </IonItem>
      )}

      {
        requestQuery.isSuccess && requestQuery.data && (
          <>
            {requestQuery.data.ugType === "F" && (
              <>
                <IonCardHeader color="primary">
                  <IonItem lines="none" color="transparent">
                    <IonCardTitle slot="start">{requestQuery.data.ugAddress}</IonCardTitle>
                      <IonLabel slot="end">Fix/Repair</IonLabel>
                  </IonItem>
                  <IonItem color="transparent">
                      <IonLabel>
                        *{requestQuery.data.ugdesc}
                      </IonLabel>
                  </IonItem>
                  <IonItem lines="none" color="transparent" >
                    <IonItemGroup slot="start">
                    <IonBadge color="dark">{requestQuery.data.ugfirstcan + requestQuery.data.ugothercan}</IonBadge>&nbsp;
                    <IonBadge color="secondary">{requestQuery.data.ugrcyclcan}</IonBadge>&nbsp;
                      <IonBadge color="success">{requestQuery.data.uggreencan}</IonBadge>
                    </IonItemGroup>
                    <IonItemGroup slot="end">
                      <IonBadge color="dark">{requestQuery.data.ugarbcan1}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan3}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan4}</IonBadge>
                    </IonItemGroup>
                  </IonItem>
                  
                 
                </IonCardHeader>
              </>
            )
            }
            {requestQuery.data.ugType === "D" && (
              <>
                <IonCardHeader color="success">
                    <IonItem lines="none" color="transparent">
                    <IonLabel slot="start">{requestQuery.data.ugAddress}</IonLabel>
                    <IonLabel slot="end">Delivery</IonLabel>
                  </IonItem>
                  <IonItem lines="none" color="transparent" >
                    <IonLabel class="item-text-wrap"><h5>*{requestQuery.data.ugdesc}</h5></IonLabel>
                  </IonItem>
                  <IonItem lines="none" color="transparent" >
                    <IonItemGroup slot="start">
                      <IonBadge color="dark">{requestQuery.data.ugfirstcan + requestQuery.data.ugothercan}</IonBadge>&nbsp;
                      <IonBadge color="secondary">{requestQuery.data.ugrcyclcan}</IonBadge>&nbsp;
                      <IonBadge color="success">{requestQuery.data.uggreencan}</IonBadge>
                    </IonItemGroup>
                    <IonItemGroup slot="end">
                      <IonBadge color="dark">{requestQuery.data.ugarbcan1}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan3}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan4}</IonBadge>
                    </IonItemGroup>
                  </IonItem>
                </IonCardHeader>
              </>
            )
            }

            {requestQuery.data.ugType === "P" && (
              <>
                <IonCardHeader color="danger">
                    <IonItem lines="none" color="transparent">
                      <IonLabel slot="start">{requestQuery.data.ugAddress}</IonLabel>
                      <IonLabel slot="end">Pickup</IonLabel>
                  </IonItem>
                  <IonItem lines="none" color="transparent" >
                    <IonLabel class="item-text-wrap"><h5>*{requestQuery.data.ugdesc}</h5></IonLabel>
                  </IonItem>
                  <IonItem lines="none" color="transparent" >
                    <IonItemGroup slot="start">
                      <IonBadge color="dark">{requestQuery.data.ugfirstcan + requestQuery.data.ugothercan}</IonBadge>&nbsp;
                      <IonBadge color="secondary">{requestQuery.data.ugrcyclcan}</IonBadge>&nbsp;
                      <IonBadge color="success">{requestQuery.data.uggreencan}</IonBadge>
                    </IonItemGroup>
                    <IonItemGroup slot="end">
                      <IonBadge color="dark">{requestQuery.data.ugarbcan1}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan3}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan4}</IonBadge>
                    </IonItemGroup>
                  </IonItem>
                </IonCardHeader>
              </>
            )
            }


            {requestQuery.data.ugType === "I" && (
              <>
                <IonCardHeader color="warning">
                  <IonItem lines="none" color="transparent">
                    <IonLabel slot="start">{requestQuery.data.ugAddress}</IonLabel>
                    <IonLabel slot="end">Inventory</IonLabel>
                  </IonItem>
                  <IonItem lines="none" color="transparent" >
                    <IonLabel class="item-text-wrap"><h5>*{requestQuery.data.ugdesc}</h5></IonLabel>
                  </IonItem>
                  <IonItem lines="none" color="transparent" >
                    <IonItemGroup slot="start">
                      <IonBadge color="dark">{requestQuery.data.ugfirstcan + requestQuery.data.ugothercan}</IonBadge>&nbsp;
                      <IonBadge color="secondary">{requestQuery.data.ugrcyclcan}</IonBadge>&nbsp;
                      <IonBadge color="success">{requestQuery.data.uggreencan}</IonBadge>
                    </IonItemGroup>
                    <IonItemGroup slot="end">
                      <IonBadge color="dark">{requestQuery.data.ugarbcan1}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan3}</IonBadge>&nbsp;
                      <IonBadge color="dark">{requestQuery.data.ugarbcan4}</IonBadge>
                    </IonItemGroup>
                  </IonItem>
                </IonCardHeader>
              </>
            )
            }

            <Formik
              initialValues={{
                notes: null,
                status: "Order completed",
                can1: requestQuery.data.ugarbcan1,
                can3: requestQuery.data.ugarbcan3,
                can4: requestQuery.data.ugarbcan4
              }}

              onSubmit={async values => {
                //await new Promise(resolve => setTimeout(resolve, 200));
                //showProcess({
                //  message: 'Processing, please wait ...',
                //});
                UpdateAppGarbageRequest(recordIndex, values.status, values.notes, values.can1, values.can3, values.can4 ).then(() =>
                {
                  
                  setShowModalComplete(false);
                });
               

              }}
            >

              {(formikProps: any) => (
                <form
                  onSubmit={formikProps.handleSubmit}
                  style={{ paddingBottom: "5px" }}
                >

                  {requestQuery.data.ugType === "I" && (<>
                  <IonItemDivider color="secondary">
                    <IonLabel>
                      Please verify the inventory count below
                    </IonLabel>
                  </IonItemDivider>

                  <IonItem color="transparent">
                    <IonLabel slot="start">Regular Can(s)</IonLabel>
                    <IonInput
                      className="AlignRight"
                      slot="end"
                      type="number"
                      name="can1"
                      value={formikProps.values.can1}
                      onIonChange={formikProps.handleChange}
                    />
                  </IonItem>

                  <IonItem color="transparent">
                    <IonLabel slot="start">Recycle Can(s)</IonLabel>
                    <IonInput
                      className="AlignRight"
                      slot="end"
                      type="number"
                      name="can3"
                      value={formikProps.values.can3}
                      onIonChange={formikProps.handleChange}
                    />
                  </IonItem>

                  <IonItem color="transparent">
                    <IonLabel slot="start">Green Can(s)</IonLabel>
                    <IonInput
                      className="AlignRight"
                      slot="end"
                      type="number"
                      name="can4"
                      value={formikProps.values.can4}
                      onIonChange={formikProps.handleChange}
                    />
                  </IonItem>
                  </>)}


                  <IonItem>
                    {requestQuery.data.ughotcust === 1 && (<IonIcon slot="start" size="large" src='/assets/icon/hot.svg' />)}
                    <IonLabel>Ticket Status</IonLabel>
                    <IonSelect
                      name="status"
                      value={formikProps.values.status}
                      onIonChange={formikProps.handleChange}
                    >
                      <IonSelectOption value="Order completed">✔ Order completed</IonSelectOption>


                      <IonSelectOption value="Can does not need replacing" >✗ Can does not need replacing</IonSelectOption>
                      <IonSelectOption value="Did not order">✗ Did not order</IonSelectOption>
                      <IonSelectOption value="No action required">✗No action required</IonSelectOption>


                      <IonSelectOption value="Can was full">✗Can was full</IonSelectOption>
                      <IonSelectOption value="No can out">✗No can out</IonSelectOption>
                      <IonSelectOption value="Unable to locate can">✗Unable to locate can</IonSelectOption>
                      
                      
                    </IonSelect>
                  </IonItem>






                  <IonItem color="transparent">
                    <IonLabel position="floating">Notes (Optional)</IonLabel>
                    <IonInput
                      type="text"
                      name="notes"
                      min="40"
                      max="200"
                      value={formikProps.values.notes}
                      onIonChange={formikProps.handleChange}
                    />
                  </IonItem>



                  <IonButton 
                    color="primary"
                    expand="block"
                    type="submit"
                    mode="ios"
                  > Submit</IonButton>

                 


                  

                </form>
              )}

            </Formik>


          </>
        )
      }
    </IonContent>

    <IonFooter>
      <IonItem >
        <IonIcon icon={chevronDownCircleOutline} size="large" color="primary" />
        <IonLabel>&nbsp;&nbsp;You can toggle the 'hot' flag to indicate the order's importance</IonLabel>
      </IonItem>
      <IonItem lines="none">
        <IonLabel>Mark Order As Hot</IonLabel>
        {requestQuery.isSuccess && (
          <IonToggle
            checked={ughotcust===1 ? true : false}
            onIonChange={async (e) => {
              if (ughotcust === 1)
                await setAppHotRequest(recordIndex, "N");
              else
                await setAppHotRequest(recordIndex, "Y");

              setShowModalComplete(false);
            }
            }
            color="danger" mode="ios" 
          ></IonToggle>
        )} 
      </IonItem>
    </IonFooter>
  </>);
}




export default ModalComplete;


            // Future to implement the photos
            //{false && (<>
            //  <IonCardHeader >
            //    <Camera id={recordIndex} setRefresh={setRefresh} />
            //    <IonItemDivider>
            //    </IonItemDivider>
            //    <IonList>
            //      {myAttachmentQuery.isSuccess && myAttachmentQuery.data.map((dataString: any) =>
            //      (
            //        <IonItem lines="none" key={dataString.filename} onClick={() => showfile(dataString.blob, dataString.ext, dataString.filename)} >
            //          <IonIcon icon={cloudDownloadOutline} size="large" color="primary" slot="start"></IonIcon>
            //          <IonLabel color="transparent">{dataString.displayFilename}</IonLabel>
            //        </IonItem>
            //      ))
            //      }
            //    </IonList>
            //  </IonCardHeader>
            //</>)}
